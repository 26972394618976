import React, { useEffect } from 'react';
import { HeaderTab } from '../commons';
import styles from './scan.module.scss';
import { FCMResult } from '../../models/faceCaptureModule';
import { ConfigProps } from './../config/ConfigPanel';
import { Screen as ConsentScreen } from '../consent';
import { RoleCode, Session } from '../../models/account';
import { useGlobalContext } from '../../globalContext';
import { ScanTabs } from './models';
import { Collector, Guide, ScanTab, Settings } from './tabs';
import Loading from '../loading';

interface Props {
    onSuccess: (res: FCMResult) => void;
    onError: (error: Error) => void;
    configuration: ConfigProps;
    setConfiguration: (config: ConfigProps) => void;
}

const getScanSelectedTab = (scanFirstLanding?: boolean, accout?: Session): ScanTabs => {
    if (scanFirstLanding) {
        return ScanTabs.Guide;
    }
    if (accout?.role === RoleCode.DataCollector) {
        return ScanTabs.DataCollectorSettings;
    }
    return ScanTabs.Scan;
};

const Scan: React.FC<Props> = ({ onSuccess, onError, setConfiguration, configuration }) => {
    const globalContext = useGlobalContext();
    const {
        scanSelectedTab: selectedTab,
        set,
        firstLandingConfig,
        account,
        scanConfiguration: { selectedLanguage },
    } = globalContext;

    const homeTabs =
        account?.role === RoleCode.DataCollector
            ? Object.values(ScanTabs)
            : Object.values(ScanTabs).filter((v) => v !== ScanTabs.DataCollectorSettings);

    const setTab = (tab: string) => {
        switch (tab) {
            case ScanTabs.Settings:
            case ScanTabs.Scan:
            case ScanTabs.Guide:
            case ScanTabs.DataCollectorSettings:
                set((previous) => ({
                    ...previous,
                    scanSelectedTab: tab,
                }));
                break;
            default:
                set((previous) => ({
                    ...previous,
                    scanSelectedTab: ScanTabs.Scan,
                }));
                console.log(new Error('Selected tab not available, redirected to Scan'));
                break;
        }
    };

    useEffect(() => {
        if (!selectedTab) {
            set((previous) => ({
                ...previous,
                scanSelectedTab: getScanSelectedTab(
                    previous.firstLandingConfig.scanFirstLanding,
                    account,
                ),
            }));
        }
    }, [set, account, selectedTab]);

    return (
        <ConsentScreen>
            {selectedTab === undefined ? (
                <Loading />
            ) : (
                <div className={styles['scan']}>
                    <HeaderTab tabs={homeTabs} selected={selectedTab} onClick={setTab} />
                    <div className={styles['scan__wrapper']}>
                        <div className={styles['scan__wrapper__panel']}>
                            {selectedTab === ScanTabs.Settings ? (
                                <Settings
                                    configuration={configuration}
                                    onConfigChange={setConfiguration}
                                    onError={onError}
                                />
                            ) : homeTabs.includes(ScanTabs.DataCollectorSettings) &&
                              selectedTab === ScanTabs.DataCollectorSettings ? (
                                <Collector
                                    configuration={configuration}
                                    onConfigChange={setConfiguration}
                                    onError={onError}
                                />
                            ) : selectedTab === ScanTabs.Guide ? (
                                <Guide
                                    account={account}
                                    firstLandingConfig={firstLandingConfig}
                                    set={set}
                                />
                            ) : (
                                <ScanTab
                                    onError={onError}
                                    onSuccess={onSuccess}
                                    language={selectedLanguage}
                                />
                            )}
                        </div>
                    </div>
                </div>
            )}
        </ConsentScreen>
    );
};

export default Scan;
