import FaceCapture, { ERROR_CODE, LANGUAGE_CODE } from '@getyoti/react-face-capture';
import '@getyoti/react-face-capture/index.css';
import React from 'react';
import { useGlobalContext } from '../../globalContext';
import { FCMResult } from '../../models/faceCaptureModule';
import { SecureMode } from '../../models/prediction';

interface Props {
    onSuccess: (res: FCMResult) => void;
    onError: (error: Error, errorCode: string) => void;
    language?: LANGUAGE_CODE;
}

const supportedBrowsersList = [
    'Android Chrome',
    'Chrome',
    'Edge',
    'Firefox',
    'IOS Safari',
    'MAC Safari',
];

export const formattedSupportedBrowsersList =
    supportedBrowsersList.length > 1
        ? `${supportedBrowsersList.slice(0, -1).join(', ')} or ${
              supportedBrowsersList[supportedBrowsersList.length - 1]
          }`
        : supportedBrowsersList[0];

type ErrorMessage =
    | 'Camera not found.'
    | 'Camera permission rejected.'
    | 'Current browser is not supported.'
    | 'Not compatible with any camera device.'
    | 'Unknown camera error.'
    | 'Face detection has failed to initialise.'
    | 'The secure session has expired'
    | 'Internal error.';

const GetErrorMessage = (error: string): ErrorMessage => {
    switch (error) {
        case ERROR_CODE.NO_CAMERA:
            return 'Camera not found.';
        case ERROR_CODE.NO_CAMERA_PERMISSION:
            return 'Camera permission rejected.';
        case ERROR_CODE.UNSUPPORTED_BROWSER:
            return 'Current browser is not supported.';
        case ERROR_CODE.OVERCONSTRAINED:
            return 'Not compatible with any camera device.';
        case ERROR_CODE.GENERIC_CAMERA_ERROR:
            return 'Unknown camera error.';
        case ERROR_CODE.FACE_DETECTION_INIT_ERROR:
            return 'Face detection has failed to initialise.';
        case ERROR_CODE.SECURE_SESSION_EXPIRED:
            return 'The secure session has expired';
        default:
            return 'Internal error.';
    }
};

const FaceCaptureModule: React.FC<Props> = ({
    onSuccess,
    onError,
    language = LANGUAGE_CODE.EN,
}) => {
    const {
        scanConfiguration: { selectedSecureMode },
    } = useGlobalContext();
    const [errorType, setErrorType] = React.useState<string>();

    const handleResetOnError = (errorCode: string) => {
        if (errorCode !== errorType) {
            onError(new Error(GetErrorMessage(errorCode)), errorCode);
            setErrorType(errorCode);
        }
    };

    return (
        <FaceCapture
            onSuccess={onSuccess}
            onError={handleResetOnError}
            secure={selectedSecureMode === SecureMode.Secure}
            language={language}
        />
    );
};

export default FaceCaptureModule;
