import { ConfigProps } from '../ConfigPanel';
import { Endpoint } from '../../../models/prediction';

export interface EndpointConfig {
    renderConfig(
        endpoint: Endpoint,
        config: ConfigProps,
        onConfigChange: (config: ConfigProps) => void,
    ): JSX.Element;
}

type EndpointConfigConstructor = new () => EndpointConfig;

export const EndpointConfigClassType: Record<Endpoint, EndpointConfigConstructor | undefined> = {
    [Endpoint.Antispoofing]: undefined,
    [Endpoint.Age]: undefined,
    [Endpoint.AgeAntispoofing]: undefined,
    [Endpoint.FaceMatching]: undefined,
};
