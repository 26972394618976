import React from 'react';
import styles from './resultAgeAntispoofing.module.scss';
import { HeaderTab } from '../../commons';
import Button from '@yoti/react-components/button';
import { PredictionResponse } from '../../../models/prediction';
import AgeAntispoofingResponse from './AgeAntispoofingResponse';
import TimeoutCircularLoading, {
    DEFAULT_LOADING_MESSAGE,
    TIMEOUT_MESSAGE,
    TIMEOUT_DELAY,
} from '../../timeout-loading';
import { useMediaQuery, MediaQuery } from '../../../hooks';
import { ConfigProps } from '../../config/ConfigPanel';

interface Props {
    image: string;
    result?: PredictionResponse;
    onBackButtonClick: () => void;
    configuration: ConfigProps;
}

const ResultAgeAntispoofing: React.FC<Props> = (props) => {
    const { image, result, onBackButtonClick, configuration } = props;

    const isLaptop = useMediaQuery(MediaQuery.lg);

    return (
        <div className={styles['result']}>
            <HeaderTab className={styles['result__header']} tabs={['Result']} />
            <div className={styles['result__panel']}>
                <img
                    className={styles['result__panel__image']}
                    src={image}
                    alt="face"
                    data-qa="image-captured"
                />
                <div className={styles['result__panel__response']}>
                    {result ? (
                        <AgeAntispoofingResponse
                            responseData={result}
                            configuration={configuration}
                        />
                    ) : (
                        <TimeoutCircularLoading
                            size={isLaptop ? '80px' : '40px'}
                            message={DEFAULT_LOADING_MESSAGE}
                            timeoutMessage={TIMEOUT_MESSAGE}
                            timeout={TIMEOUT_DELAY}
                        />
                    )}
                </div>
            </div>
            <div className={styles['result__back-button']}>
                <Button onClick={onBackButtonClick}>Try again</Button>
            </div>
        </div>
    );
};

export default ResultAgeAntispoofing;
