import { RoleCode } from '../models/account';
import { FCMResult } from './faceCaptureModule';
import Config from '../utils/config';

// Endpoints information can be found here: https://lampkicking.atlassian.net/wiki/spaces/AS/pages/2036662599/AI+Services+Production+Internal+API+v1.4.0
export enum Endpoint {
    Antispoofing = 'v1/antispoofing',
    Age = 'v1/age',
    AgeAntispoofing = 'v1/age-antispoofing',
    FaceMatching = 'face-matching',
}

export enum Sections {
    Endpoint = 'endpoint',
    Metadata = 'metadata',
    LevelOfAssurance = 'levelOfAssurance',
    SecureMode = 'secureMode',
    AgeDisplayMode = 'ageDisplayMode',
    Language = 'language',
    ConfigWrapper = 'configWrapper',
}

const AnyRole = 'any';

export const getEndpointName = (endpoint: Endpoint) => {
    switch (endpoint) {
        case Endpoint.Antispoofing:
            return 'Antispoofing';
        case Endpoint.AgeAntispoofing:
            return 'Age Antispoofing';
        case Endpoint.Age:
            return 'Age';
        case Endpoint.FaceMatching:
            return 'Face Matching';
        default:
            return 'Unknown';
    }
};

export enum MetadataField {
    Mobile = 'mobile',
    Laptop = 'laptop',
    Unknown = 'unknown',
}

export enum LevelOfAssuranceField {
    Low = 'low',
    Medium = 'medium',
    High = 'high',
    VeryHigh = 'very_high',
}

export enum ExpectedResult {
    Real = 'real',
    DisplayAttack = 'display_attack',
    PrintAttack = 'print_attack',
    MaskAttack = 'mask_attack',
}

export enum ExpectedLocation {
    Indoor = 'indoor',
    Outdoor = 'outdoor',
}

export enum ExpectedBrightness {
    Dark = 'dark',
    Normal = 'normal',
    Bright = 'bright',
}

export const getMetadataFieldName = (metadata: MetadataField) => {
    switch (metadata) {
        case MetadataField.Mobile:
            return 'Mobile';
        case MetadataField.Laptop:
            return 'Laptop';
        default:
            return 'Unknown';
    }
};

function includesRoleOrAny(base: string[], searchRole: string | undefined): boolean {
    return (searchRole !== undefined && base.includes(searchRole)) || base.includes(AnyRole);
}

export const validRoleConfig = (setting?: Sections, role?: RoleCode) => {
    switch (setting) {
        case Sections.Endpoint:
            return includesRoleOrAny(Config.allowedRolesForEndpointsConfig, role);
        case Sections.Metadata:
            return includesRoleOrAny(Config.allowedRolesForMetadataConfig, role);
        case Sections.LevelOfAssurance:
            return includesRoleOrAny(Config.allowedRolesForLevelOfAssuranceConfig, role);
        case Sections.SecureMode:
            return includesRoleOrAny(Config.allowedRolesForSecureModeConfig, role);
        case Sections.ConfigWrapper:
            return includesRoleOrAny(Config.allowedRolesForConfigWrapperConfig, role);
        case Sections.AgeDisplayMode:
            return includesRoleOrAny(Config.allowedRolesForAgeDisplayModeConfig, role);
        case Sections.Language:
            return includesRoleOrAny(Config.allowedRolesForLanguageConfig, role);
    }
};

export interface RequestMetadataField {
    device: string;
}

export const getMetadataStruct = (
    metadataDeviceField?: MetadataField,
): RequestMetadataField | undefined => {
    if (!metadataDeviceField) {
        return undefined;
    }
    return { device: metadataDeviceField };
};

export enum Liveness {
    Real = 'real',
    Fake = 'fake',
    Undetermined = 'undertermined',
}

export function LivenessOutput(result: Liveness): string {
    switch (result) {
        case Liveness.Real:
            return 'Passed';
        case Liveness.Fake:
            return 'Failed';
        case Liveness.Undetermined:
            return 'Undetermined';
    }
}

export interface PredictionResponse {
    prediction?: Liveness;
    age?: number;
    stDev?: number;
    errorMessage?: string;
    errorCode?: string;
}

export interface FaceMatchingResponseData {
    score: number;
    errorMessage?: string;
    errorCode?: string;
}

export interface RequestBody extends FCMResult {
    metadata?: RequestMetadataField;
    level_of_assurance?: LevelOfAssuranceField;
}

export enum SecureMode {
    NonSecure = 'nonSecure',
    Secure = 'secure',
}

export const getSecureModeName = (mode: SecureMode) => {
    switch (mode) {
        case SecureMode.NonSecure:
            return 'Non secure';
        case SecureMode.Secure:
            return 'Secure';
        default:
            return 'Unknown';
    }
};

export enum AgeDisplayMode {
    Range = 'range',
    Raw = 'raw',
}
