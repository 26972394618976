import React from 'react';
import { Config as ConsentConfig } from '../consent';
import { ConfigSubpanelProps } from './ConfigPanel';
import { ConfigWrapper } from './ConfigWrapper';
import EndpointsSection from './endpoints';
import MetadataFieldSection from './metadataField';
import LevelOfAssuranceFieldSection from './levelOfAssurance';
import {
    Endpoint,
    LevelOfAssuranceField,
    MetadataField,
    SecureMode,
    validRoleConfig,
    Sections,
    AgeDisplayMode,
} from '../../models/prediction';

import { useGlobalContext } from '../../globalContext';
import Config from '../../utils/config';
import SecureModeSection from './secure/SecureModeSection';
import AgeDisplayModeSection from './ageDisplayMode/AgeDisplayModeSection';
import { LANGUAGE_CODE } from '@getyoti/react-face-capture';
import LanguageFieldSection from './languageField/LanguageFieldSection';

// TODO: AITOOL-2804 - Replace this for Object.values(AgeAntispoofingEndpoints) after the refactor.
const endpointsList = [Endpoint.AgeAntispoofing];
const metadataFieldsList = Object.values(MetadataField);
const levelOfAssuranceFieldList = Config.levelAssuranceValues;
const secureModeList = Object.values(SecureMode);
const ageDisplayModeList = Object.values(AgeDisplayMode);

export const AgeAntispoofingConfigPanel: React.FC<ConfigSubpanelProps> = ({
    config: initialConfig,
    onConfigChange,
    onError,
}) => {
    const { account } = useGlobalContext();
    const currentRole = account?.role;
    const onLevelOfAssuranceChange = (element?: LevelOfAssuranceField) =>
        onConfigChange({
            ...initialConfig,
            selectedLevelOfAssuranceField:
                element === initialConfig.selectedLevelOfAssuranceField ? undefined : element,
        });
    const onMetadataChange = (element?: MetadataField) =>
        onConfigChange({
            ...initialConfig,
            selectedMetadataField:
                element === initialConfig.selectedMetadataField ? undefined : element,
        });
    const onSecureModeChange = (value: SecureMode) =>
        onConfigChange({
            ...initialConfig,
            selectedSecureMode: value,
        });
    const onAgeDisplayModeChange = (value: AgeDisplayMode) =>
        onConfigChange({ ...initialConfig, selectedAgeDisplayMode: value });
    const onLanguageFieldChange = (value: LANGUAGE_CODE) =>
        onConfigChange({ ...initialConfig, selectedLanguage: value });
    const onConsentChange = (consentGiven: boolean) =>
        onConfigChange({ ...initialConfig, consent: consentGiven });
    return (
        <>
            {validRoleConfig(Sections.Endpoint, currentRole) && (
                <>
                    <EndpointsSection
                        endpointsList={endpointsList}
                        config={initialConfig}
                        onConfigChange={onConfigChange}
                    />
                </>
            )}
            {validRoleConfig(Sections.Metadata, currentRole) && (
                <>
                    <MetadataFieldSection
                        metadataFieldList={metadataFieldsList}
                        value={initialConfig.selectedMetadataField}
                        onChange={onMetadataChange}
                        disabled={initialConfig.selectedEndpoint === Endpoint.Age}
                    />
                </>
            )}
            {validRoleConfig(Sections.LevelOfAssurance, currentRole) && (
                <>
                    <LevelOfAssuranceFieldSection
                        levelOfAssuranceFieldList={levelOfAssuranceFieldList}
                        value={initialConfig.selectedLevelOfAssuranceField}
                        onChange={onLevelOfAssuranceChange}
                        disabled={initialConfig.selectedEndpoint === Endpoint.Age}
                    />
                </>
            )}
            {validRoleConfig(Sections.SecureMode, currentRole) && (
                <>
                    <SecureModeSection
                        secureModeList={secureModeList}
                        value={initialConfig.selectedSecureMode}
                        onChange={onSecureModeChange}
                    />
                </>
            )}
            {validRoleConfig(Sections.AgeDisplayMode, currentRole) && (
                <>
                    <AgeDisplayModeSection
                        ageDisplayModeList={ageDisplayModeList}
                        value={initialConfig.selectedAgeDisplayMode}
                        onChange={onAgeDisplayModeChange}
                        disabled={
                            initialConfig.selectedEndpoint !== Endpoint.Age &&
                            initialConfig.selectedEndpoint !== Endpoint.AgeAntispoofing
                        }
                    />
                </>
            )}
            {validRoleConfig(Sections.Language, currentRole) && (
                <>
                    <LanguageFieldSection
                        value={initialConfig.selectedLanguage}
                        onChange={onLanguageFieldChange}
                    />
                </>
            )}
            {validRoleConfig(Sections.ConfigWrapper, currentRole) && (
                <>
                    <ConfigWrapper onError={onError} property={'saveImagesScanTool'} />
                </>
            )}
            {Config.showConsent && (
                <ConsentConfig onChange={onConsentChange} consentGiven={initialConfig.consent} />
            )}
        </>
    );
};
