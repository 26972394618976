import { useState, useEffect } from 'react';
import { useLocalStorage } from './storage';
import { ConfigProps, isConfig } from './../components/config/ConfigPanel';
import { EndpointConfigClassType } from '../components/config/endpoints/endpointClasses';
import { RoleCode } from '../models/account';
import { Sections, validRoleConfig } from '../models/prediction';
import { DEFAULT_CONFIG } from '../components/scan-panel/models';

export function useConfigStorage(
    key: string,
    defaultConfig: ConfigProps,
    role?: RoleCode,
): [ConfigProps, (value: ConfigProps) => void] {
    if (!role) role = RoleCode.User;

    const [configuration, setConfiguration] = useLocalStorage(key, defaultConfig, isConfig);

    const [configWithClasses, setConfigWithClasses] = useState<ConfigProps>(
        Object.assign(new ConfigProps(), configuration),
    );

    const configDep = JSON.stringify(configuration);

    useEffect(() => {
        const configuration = JSON.parse(configDep) as ConfigProps;
        configuration.endpoints.forEach((endpoint, i, endpoints) => {
            const endpointClass = EndpointConfigClassType[endpoint.key];
            if (endpointClass) {
                endpoints[i].cfg = Object.assign(new endpointClass(), endpoint.cfg);
            }
        });

        configuration.selectedSecureMode = validRoleConfig(Sections.SecureMode, role)
            ? configuration.selectedSecureMode
            : DEFAULT_CONFIG.selectedSecureMode;
        configuration.selectedMetadataField = validRoleConfig(Sections.Metadata, role)
            ? configuration.selectedMetadataField
            : DEFAULT_CONFIG.selectedMetadataField;
        configuration.selectedLevelOfAssuranceField = validRoleConfig(
            Sections.LevelOfAssurance,
            role,
        )
            ? configuration.selectedLevelOfAssuranceField
            : DEFAULT_CONFIG.selectedLevelOfAssuranceField;
        configuration.selectedLanguage = validRoleConfig(Sections.Language, role)
            ? configuration.selectedLanguage
            : DEFAULT_CONFIG.selectedLanguage;

        setConfigWithClasses({
            selectedEndpoint: configuration.selectedEndpoint,
            selectedSecureMode: configuration.selectedSecureMode,
            selectedMetadataField: configuration.selectedMetadataField,
            selectedLevelOfAssuranceField: configuration.selectedLevelOfAssuranceField,
            selectedExpectedResult: configuration.selectedExpectedResult,
            selectedExpectedLocation: configuration.selectedExpectedLocation,
            selectedExpectedBrightness: configuration.selectedExpectedBrightness,
            endpoints: configuration.endpoints,
            selectedAgeDisplayMode: configuration.selectedAgeDisplayMode,
            selectedLanguage: configuration.selectedLanguage,
            consent: configuration.consent,
        });
    }, [configDep, role, defaultConfig]);

    return [configWithClasses, setConfiguration];
}

export default useConfigStorage;
