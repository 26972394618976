import React, { useCallback, useState } from 'react';
import { MediaQuery, useMediaQuery } from '../../../hooks';
import { FaceMatchingTwoPanels } from '../../commons';
import Button, { BUTTON_VARIATIONS } from '@yoti/react-components/button';
import classNames from 'classnames';
import { FCMResult } from '../../../models/faceCaptureModule';
import { getErrorMessage } from '../../scan/tabs/ScanTab';
import { FCMErrorHandler } from '../../fcm-error-handler/FCMErrorHandler';
import FaceCaptureModule from '../../face-capture-module';
import Icon from '@yoti/react-components/icon';
import * as iconsList from '@yoti/react-components/icons-list';
import SelfieCapture from '../../selfie-capture';
import styles from './capture.module.scss';
import { FaceMatchingResponseData } from '../../../models/prediction';
import { LANGUAGE_CODE } from '@getyoti/react-face-capture';

export enum FaceMatchingScanImages {
    FirstImage = 'First',
    ScondImage = 'Second',
    Empty = '',
}

export interface FaceMatchingPanelState {
    firstImage: string;
    secondImage: string;
    openFaceCapture: FaceMatchingScanImages;
    fcmError?: string;
}

export const defaultState: FaceMatchingPanelState = {
    firstImage: '',
    secondImage: '',
    openFaceCapture: FaceMatchingScanImages.Empty,
    fcmError: undefined,
};

export interface FaceMatchingCaptureResponse {
    responseData: FaceMatchingResponseData;
    firstImage: string;
    secondImage: string;
}

interface Props {
    language: LANGUAGE_CODE;
    onError: (error: Error) => void;
    onSuccess: (res: FaceMatchingCaptureResponse) => void;
}

const Capture: React.FC<Props> = ({ language, onError, onSuccess }) => {
    const [state, setState] = useState<FaceMatchingPanelState>(defaultState);
    const { firstImage, secondImage, openFaceCapture, fcmError } = state;

    const isLaptop = useMediaQuery(MediaQuery.lg);

    const onImageTaken = ({ img }: FCMResult) => {
        if (openFaceCapture === FaceMatchingScanImages.FirstImage) {
            setState((previous) => ({
                ...previous,
                firstImage: img,
                openFaceCapture: FaceMatchingScanImages.Empty,
            }));
        } else {
            setState((previous) => ({
                ...previous,
                secondImage: img,
                openFaceCapture: FaceMatchingScanImages.Empty,
            }));
        }
    };

    const onFcmError = useCallback(
        (error: Error, code: string) => {
            onError(error);
            setState((prev) => ({ ...prev, fcmError: getErrorMessage(code) }));
        },
        [onError, setState],
    );

    const onFcmReset = useCallback(() => setState((prev) => ({ ...prev, fcmError: undefined })), [
        setState,
    ]);

    const faceMatchingPetition = () => {
        return new Promise<number>((resolve) => {
            resolve(Math.random() * (10 - 1) + 1);
        });
    };

    // TODO: AITOOL-1995: When the model will be ready, we need to change this for an actual fetch prediction to the model.
    const predict = async () => {
        const score = await faceMatchingPetition();
        onSuccess({ firstImage: firstImage, secondImage: secondImage, responseData: { score } });
    };

    const getPanel = (primary: boolean) => {
        return (
            <div className={styles['capture__selfie-capture-wrapper']}>
                <SelfieCapture
                    image={primary ? firstImage : secondImage}
                    primaryPanel={primary || !isLaptop}
                    onChange={(img: string) => {
                        if (primary) {
                            setState((previous) => ({
                                ...previous,
                                firstImage: img,
                            }));
                        } else {
                            setState((previous) => ({
                                ...previous,
                                secondImage: img,
                            }));
                        }
                    }}
                    onError={onError}
                    onTakePhotoClick={() =>
                        setState((previous) => ({
                            ...previous,
                            openFaceCapture: primary
                                ? FaceMatchingScanImages.FirstImage
                                : FaceMatchingScanImages.ScondImage,
                        }))
                    }
                />
            </div>
        );
    };

    return (
        <>
            {openFaceCapture ? (
                <div className={styles['capture__fixed-size-wrapper']}>
                    <div className={styles['capture__fixed-size-wrapper__panel']}>
                        <FCMErrorHandler errorMessage={fcmError || ''} onReset={onFcmReset}>
                            <FaceCaptureModule
                                onSuccess={onImageTaken}
                                onError={onFcmError}
                                language={language}
                            />
                        </FCMErrorHandler>
                        <Icon
                            component={iconsList[`goBack`]}
                            className={styles['capture__fixed-size-wrapper__back-button']}
                            onClick={() =>
                                setState((previous) => ({
                                    ...previous,
                                    openFaceCapture: FaceMatchingScanImages.Empty,
                                }))
                            }
                        />
                    </div>
                </div>
            ) : (
                <>
                    <FaceMatchingTwoPanels data-qa="capture">
                        {{
                            Left: isLaptop ? (
                                <div className={styles['capture__column']}>
                                    <div className={styles['capture__title']}>
                                        <span>First image</span>
                                    </div>
                                    {getPanel(true)}
                                </div>
                            ) : (
                                <div className={styles['capture__column']}>
                                    <div className={styles['capture__title']}>
                                        <span>Add both images to proceed</span>
                                    </div>
                                    <div className={styles['capture__mobile-images-container']}>
                                        {getPanel(true)}
                                        {getPanel(false)}
                                    </div>
                                    <div className={styles['capture__predict-button']}>
                                        <Button
                                            variation={BUTTON_VARIATIONS.SUCCESS}
                                            onClick={() => predict()}
                                            disabled={!firstImage || !secondImage}
                                        >
                                            Predict
                                        </Button>
                                    </div>
                                </div>
                            ),
                            Right: isLaptop ? (
                                <div className={styles['capture__column']}>
                                    <div
                                        className={classNames(
                                            styles['capture__title'],
                                            styles['capture__title--secondary'],
                                        )}
                                    >
                                        <span>Second image</span>
                                    </div>

                                    {getPanel(false)}
                                </div>
                            ) : (
                                <></>
                            ),
                        }}
                    </FaceMatchingTwoPanels>
                    {isLaptop && (
                        <div className={styles['capture__predict-button']}>
                            <Button
                                variation={BUTTON_VARIATIONS.SUCCESS}
                                onClick={() => predict()}
                                disabled={!firstImage || !secondImage}
                            >
                                Predict
                            </Button>
                        </div>
                    )}
                </>
            )}
        </>
    );
};

export default Capture;
