import { LANGUAGE_CODE } from '@getyoti/react-face-capture';
import React from 'react';
import Config from '../../utils/config';
import { TwoSidesPanel } from '../commons';
import {
    Endpoint,
    MetadataField,
    LevelOfAssuranceField,
    SecureMode,
    AgeDisplayMode,
    ExpectedLocation,
    ExpectedBrightness,
    ExpectedResult,
} from './../../models/prediction';
import styles from './configPanel.module.scss';
import { EndpointConfig, EndpointConfigClassType } from './endpoints/endpointClasses';

export interface EndpointConfigElement {
    key: Endpoint;
    cfg?: EndpointConfig;
}

export class ConfigProps {
    constructor(
        public selectedEndpoint: Endpoint = Config.defaultConfig.endpoint,
        public selectedSecureMode: SecureMode = Config.defaultConfig.mode,
        public selectedMetadataField?: MetadataField,
        public selectedLevelOfAssuranceField?: LevelOfAssuranceField,
        public selectedExpectedResult?: ExpectedResult,
        public selectedExpectedLocation?: ExpectedLocation,
        public selectedExpectedBrightness?: ExpectedBrightness,
        public endpoints: EndpointConfigElement[] = [
            { key: Endpoint.Antispoofing },
            { key: Endpoint.Age },
            { key: Endpoint.AgeAntispoofing },
            { key: Endpoint.FaceMatching },
        ],
        public selectedAgeDisplayMode: AgeDisplayMode = Config.defaultConfig.ageDisplayMode,
        public selectedLanguage: LANGUAGE_CODE = Config.defaultConfig.language,
        public consent: boolean = false,
    ) {}
}

export const fillUndefinedValues = (config: ConfigProps) => {
    if (!config.selectedLanguage) config.selectedLanguage = Config.defaultConfig.language;
};

/**
 * TypeGuard for the ConfigProps type.
 * @param config Object that will be checked.
 */
export function isConfig(config: any): config is ConfigProps {
    const typedConfig = config as ConfigProps;

    // Check the properties exist in order to determine if the type is correct.
    if (
        !typedConfig.selectedEndpoint ||
        !typedConfig.endpoints ||
        !typedConfig.endpoints.length ||
        !Object.values(Endpoint).includes(typedConfig.selectedEndpoint)
    ) {
        return false;
    }

    // Check that the selected metadata is valid if it exists.
    if (
        typedConfig.selectedMetadataField &&
        !Object.values(MetadataField)?.includes(typedConfig.selectedMetadataField)
    ) {
        return false;
    }

    // Check that the selected level of assurance is valid if it exists.
    if (
        typedConfig.selectedLevelOfAssuranceField &&
        (!Object.values(LevelOfAssuranceField)?.includes(
            typedConfig.selectedLevelOfAssuranceField,
        ) ||
            !Config.levelAssuranceValues.includes(typedConfig.selectedLevelOfAssuranceField))
    ) {
        return false;
    }

    // Check that the selected expected result is valid if it exists.
    if (
        typedConfig.selectedExpectedResult &&
        !Object.values(ExpectedResult)?.includes(typedConfig.selectedExpectedResult)
    ) {
        return false;
    }

    // Check that the selected expected location is valid if it exists.
    if (
        typedConfig.selectedExpectedLocation &&
        !Object.values(ExpectedLocation)?.includes(typedConfig.selectedExpectedLocation)
    ) {
        return false;
    }

    // Check that the selected expected brightness is valid if it exists.
    if (
        typedConfig.selectedExpectedBrightness &&
        !Object.values(ExpectedBrightness)?.includes(typedConfig.selectedExpectedBrightness)
    ) {
        return false;
    }

    // Check that the secure mode exists.
    if (
        !typedConfig.selectedSecureMode ||
        !Object.values(SecureMode).includes(typedConfig.selectedSecureMode)
    ) {
        return false;
    }

    const endpointConfigTypesWrong = typedConfig.endpoints.filter((endpoint) => {
        return endpoint.cfg !== EndpointConfigClassType[endpoint.key];
    });

    return endpointConfigTypesWrong.length <= 0;
}

export interface ConfigSubpanelProps {
    config: ConfigProps;
    onConfigChange: (config: ConfigProps) => void;
    onError: (error: Error) => void;
}

const STORAGE_MESSAGE = 'Configuration is automatically stored on the browser cache.';

const ConfigPanel: React.FC = ({ children }) => {
    return (
        <>
            <TwoSidesPanel mobileContentClassName={styles['panel__mobile-content']}>
                {{
                    Left: <div className={''}>{children}</div>,
                    Right: (
                        <div
                            className={styles['panel__storage-message']}
                            data-qa={'storage-message'}
                        >
                            {STORAGE_MESSAGE}
                        </div>
                    ),
                    Header: <>{`v${Config.version}`}</>,
                }}
            </TwoSidesPanel>
        </>
    );
};

export default ConfigPanel;
