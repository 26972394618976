import React from 'react';
import Config from '../../../utils/config';
import Icon from '@yoti/react-components/icon';
import * as iconsList from '@yoti/react-components/icons-list';
import styles from './ageAntispoofingResponse.module.scss';
import { Liveness, LivenessOutput } from '../../../models/prediction';
import { PredictionResponse } from '../../../models/prediction';
import classNames from 'classnames';
import { ConfigProps } from '../../config/ConfigPanel';
import Response from './../Response';
import { AgeDisplayer } from './age-displayer/AgeDisplayer';
import { useAgeRangeInterval } from '../../../hooks';

interface Props {
    responseData?: PredictionResponse;
    configuration: ConfigProps;
}

const AgeAntispoofingResponse: React.FC<Props> = (props) => {
    const { responseData } = props;

    const { prediction, age, stDev, errorCode, errorMessage } = responseData || {};

    const interval = useAgeRangeInterval();

    const livenessCheck = () => {
        switch (prediction) {
            case Liveness.Real:
                return (
                    <span
                        data-qa="liveness-result"
                        className={classNames(
                            styles['response__text'],
                            styles['response__text--passed'],
                        )}
                    >
                        {LivenessOutput(prediction)}
                    </span>
                );
            case Liveness.Fake:
                return (
                    <span
                        data-qa="liveness-result"
                        className={classNames(
                            styles['response__text'],
                            styles['response__text--failed'],
                        )}
                    >
                        {LivenessOutput(prediction)}
                    </span>
                );
            case Liveness.Undetermined:
                return (
                    <span
                        data-qa="liveness-result"
                        className={classNames(
                            styles['response__text'],
                            styles['response__text--undetermined'],
                        )}
                    >
                        {LivenessOutput(prediction)}
                    </span>
                );
            default:
                return undefined;
        }
    };

    const liveness = livenessCheck();
    const agePredictionIsValid = stDev !== undefined && stDev <= Config.stdDevThreshold;

    return (
        <Response errorCode={errorCode} errorMessage={errorMessage}>
            {liveness && (
                <>
                    <Icon
                        component={iconsList['faceVerified']}
                        className={styles['response__icon']}
                    />
                    <span
                        className={classNames(
                            styles['response__text'],
                            styles['response__text--bold'],
                        )}
                    >{`Liveness check: `}</span>
                    {liveness}
                </>
            )}
            {age && (
                <AgeDisplayer
                    agePredictionIsValid={agePredictionIsValid}
                    age={age}
                    interval={interval}
                    data-qa="age-info"
                />
            )}
            {age && !agePredictionIsValid && (
                <div
                    data-qa="undetermined-age-info"
                    className={classNames(
                        styles['response__age-accuracy-error'],
                        styles['response__text--secondary'],
                    )}
                >
                    * Please try again.
                </div>
            )}
        </Response>
    );
};

export default AgeAntispoofingResponse;
