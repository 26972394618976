import { ALERT_TYPES } from '@yoti/react-components/alert';
import React, { useCallback, useEffect } from 'react';
import { useGlobalContext } from '../../globalContext';
import { useNotify, usePredict } from '../../hooks';
import { FCMResult } from '../../models/faceCaptureModule';
import { Endpoint, getMetadataStruct, RequestBody } from '../../models/prediction';
import { ConfigProps } from '../config/ConfigPanel';
import ResultAgeAntispoofing from '../result/age-antispoofing';
import Scan from '../scan';
import { SESSION_KEY } from '../../utils/constants/storage';

interface ScanPanelState {
    fcmResult: FCMResult;
}

const ScanPanel: React.FC = () => {
    const [state, setState] = React.useState<ScanPanelState>({ fcmResult: { img: '' } });
    const {
        scanConfiguration: configuration,
        setScanConfiguration: setConfiguration,
    } = useGlobalContext();
    const { predict, prediction, error, abort } = usePredict(
        configuration.selectedEndpoint,
        configuration.selectedSecureMode,
    );

    const { img } = state.fcmResult;

    const notify = useNotify();

    const notifyError = useCallback(
        (error: Error) => {
            if (localStorage.getItem(SESSION_KEY)) {
                notify({
                    message: error.message,
                    type: ALERT_TYPES.DANGER,
                    icon: true,
                });
            }
        },
        [notify],
    );

    // The /age endpoint doesn't need metadata and level of assurance to make the request to the model.
    const isAgeEndpoint = () => configuration.selectedEndpoint !== Endpoint.Age;

    const onSuccess = (res: FCMResult) => {
        setState((previous) => ({ ...previous, fcmResult: res }));
        const data: RequestBody = {
            ...res,
            metadata: isAgeEndpoint()
                ? getMetadataStruct(configuration.selectedMetadataField)
                : undefined,
            level_of_assurance: isAgeEndpoint()
                ? configuration.selectedLevelOfAssuranceField
                : undefined,
        };
        predict(data);
    };
    const onError = useCallback((error: Error) => notifyError(error), [notifyError]);
    const backCallback = useCallback(() => {
        setState((previous) => ({ ...previous, fcmResult: { img: '' }, result: undefined }));
    }, []);

    const onCfgChange = useCallback(
        (cfg: ConfigProps) => {
            setConfiguration(cfg);
            backCallback(); // Reset prediction as the configuration changed.
        },
        [setConfiguration, backCallback],
    );

    // Abort the prediction request if the user navigates to another page.
    useEffect(() => {
        return abort;
    }, [abort]);

    // Reset the image if the prediction request fails.
    useEffect(() => {
        if (error && img) {
            setState({ fcmResult: { img: '' } });
            onError(error);
        }
    }, [error, onError, img]);

    return img ? (
        <ResultAgeAntispoofing
            image={img}
            result={prediction}
            onBackButtonClick={backCallback}
            configuration={configuration}
        />
    ) : (
        <Scan
            onSuccess={onSuccess}
            onError={onError}
            configuration={configuration}
            setConfiguration={onCfgChange}
        />
    );
};

export default ScanPanel;
